/* variables */
:root {
  --primary-dark: #495e57;
  --primary-light: #f4ce14;
  --secondary-dark-pink: #ee9272;
  --secondary-light-pink: #fbdabb;
  --secondary-white: #edefee;
  --secondary-black: #333333;

  --text-18px: 1.125rem;
  --text-48px: 3rem;
  --text-64px: 4rem;
}

/* resets */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth
}

/* font family imports */
@import url('https://fonts.googleapis.com/css2?family=Karla&family=Markazi+Text&display=swap');

/* text styles */

/* font family setup */
body {
  font-family: "Karla", sans-serif;
  line-height: 1.5;
  font-weight: normal;
  text-transform: none;
}

.displayTitle,
.subTitle,
.regularTitle {
  font-family: "Markazi Text", serif;
}

/* font size setup */
.leadText,
.cardTitle {
  font-size: var(--text-18px);
}

.subTitle,
.regularTitle {
  font-size: var(--text-48px);
}

.displayTitle {
  font-size: var(--text-64px);
}

/* font weight setup */
.subTitle,
p,
.regularTitle {
  font-weight: 400;
}

.displayTitle,
.leadText,
.highlightText {
  font-weight: 500;
}

.cardTitle {
  font-weight: 600;
}

.sectionTitle,
.sectionCategories {
  font-weight: 700;
}

/* setup text transforms */
.sectionTitle {
  text-transform: uppercase;
}

/* initial setup */
body {
  background-color: var(--secondary-white);
}

#root {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

header {
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

header nav ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-Style: none;
}

header nav ul li a {
  text-decoration: none;
  padding: 0px 10px;
  color: var(--primary-dark);
}

.hamburgerMenuBtn {
  display: none;
}

footer>div {
  align-items: flex-start;
  margin-bottom: 20px;
  margin: auto 10px;
  text-align: center;
}

.heroBackground {
  background-color: var(--primary-dark);
}

.roundedBorder {
  border-radius: 16px;
}

.star {
  background-image: url(./assets//icons/star.png);
}

.cta {
  background-color: var(--primary-light);
  padding: 10px;
  color: var(--primary-dark);
  border-radius: 16px;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
}

.cta:hover {
  background-color: var(--secondary-black);
  color: var(--secondary-white);
}

.submit {
  background-color: var(--primary-dark);
  padding: 10px;
  color: var(--secondary-white);
  border-radius: 16px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.submit:hover {
  background-color: var(--secondary-black);
}

.confirmationBackdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  transition-duration: 1s;
  opacity: 0;
  display: none;
}

.confirmation {
  position: fixed;
  top: calc(50vh - 200px);
  left: calc(50vw - 175px);
  min-width: 350px;
  max-width: 400px;
  min-height: 400px;
  border-radius: 16px;
  border: 1px solid var(--primary-light);
  background-color: var(--primary-dark);
  color: var(--secondary-white);
  padding: 10px 20px;
  transition-duration: 3s;
  color: var(--secondary-white);
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-top: 20px;
  font-size: small;
}

.show {
  display: initial;
  opacity: 1;
  transition-duration: 1s;
}

.onBlur {
  color: var(--secondary-dark-pink);
  font-size: small;
  padding-left: 10px;
  margin-top: -15px;
}

/* responsive media queries */

footer,
footer>div,
footer>div>* {
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;

    .sectionCategories {
      margin-top: 10px;
    }
  }
}

@media (max-width: 768px) {
  header nav ul {
    display: none;
  }

  header nav:last-child {
    text-align: end;
  }

  header {
    position: relative;
  }

  .hamburgerMenuBtn {
    display: initial;
    position: absolute;
    right: 15px;
    top: 2.3vh;
    transform: translate(0, -50%);
    cursor: pointer;
  }

  .openNav {
    display: flex;
    flex-direction: column;
  }
}

.heroBackground img {
  @media (max-width: 970px) {
    display: none;
  }
}

@media (max-width: 500px) {
  .displayTitle {
    font-size: calc(var(--text-64px) * 0.95);
  }

  .regularTitle,
  .subTitle {
    font-size: calc(var(--text-48px) * 0.85);
  }

  .leadText,
  .cardTitle {
    font-size: calc(var(--text-18px) * 0.95);
  }
}

@media (min-width: 768px) {
  .confirmation {
    transform: scale(1.5);
  }
}